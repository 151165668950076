.nav{
    display: flex;
    justify-content: space-between;
}
.Navbar{   
    background-color: #fff;
    width: 100%;
    height: 100px;
    z-index: 1;
    transition: 0.4s all ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0px 40px 0px 70px;
    box-shadow: 0px 15px 25px -15px var(--purpleColor);
    z-index: 999;
}
.Navbar ul{
    display: flex;
    list-style-type: none;
    align-items: center;
}
.Navbar ul li{
    font-size: 17px;
    margin-right: 50px;
    padding-top: 26px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.4s all ease-out;
    text-align: center;
}
.scrolled{
    position: fixed;
    background: #fff;
    box-shadow: 0px 15px 10px -15px #1e3f50;
    top:0px !important;
    color: #fff;
}
.navbar-link{
    color: var(--purpleColor);
    border: none;
    transition: 0.2s all ease;
    font-family: var(--textFamily);
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    /* line-height: 1.4; */
    text-transform: uppercase;
}

.navbar-link:hover{
    color: var(--primaryColor)
}
.active{
    color: var(--primaryColor);
    text-decoration: none; 
}
.nav-icon{
    display: none;
    cursor:pointer;
}
.logo{
    align-items: center;
    margin-left:20px;  
}
.logo img{
    width: 100%;
    height:90px; 
}
/* .dropdownlist{
    display: block;
} */
.newdropdown{
    display:none;
    position: absolute;
    top:80px;
    margin:0;
    list-style: none;
    padding:0;
    text-align: left;
}
.newdropdown li{
    background: var(--purpleColor);
    padding: 5px 10px;
    width:100px;
    height:35px;
    color:#fff;
    transition:0.4s all ease;
    z-index:50
}

.newdropdwon-navlink{
    text-decoration: none;
    color: #fff;
    font-size:17px;
    font-family: var(--textFamily);
    font-weight:600;
    transition:0.4s all ease;
}
.newdropdown li:hover{
    background: var(--primaryColor);
    color:#fff;
}
.newdropdown li .active{
    color:var(--primaryColor);
}
.newdropdown .newdropdwon-navlink:hover {
    color:#fff;
}
.dropdownlist:hover .newdropdown{
    display:block;
}
@media screen and (max-width:960px){
    .nav-menu{
        display: flex;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 90px;
        left: -110%;
        opacity: 1;
        transition: all 0.5s ease;
        text-align: left;
        color: #fff;
    }
    .nav-menu.active {
        background: var(--primaryColor);
        left: 0px;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1; 
    }
    .navbar-link{
        color: white;
        border: none;
        transition: 0.2s all ease;
        text-align: left!important;
    }
    .navbar-link:hover, .active{
        color: var(--purpleColor);
        text-decoration: none; 
    }
    .nav-icon{
        display: block;
        font-size: 30px;
        color: var(--purpleColor);
        transition: 0.4s all ease;
    }
    .nav-icon:hover {
        display:block;
        color:var(--primaryColor);
    }
    .nav-icon:hover ul{
        display: block;
        margin-top: 50px;
    }
}
@media screen and (max-width:760px){
    .Navbar{   
        align-items: left;
        padding:0px 40px 0px 10px;
    }
    .logo{
        align-items: left!important;
        margin-left:0px; 
        text-align: left;
        width: 32%;
        
    }
    .logo img{
        width: 100%;
        height:85px; 
        text-align: left;
    }
}