.pagesheaderpage{
    background:var(--primaryColor)
}
.pagesheaderpage .otherpagestitle{
    font-size: 50px;
    color:#fff;
    font-family: var(--textFamily);
    padding: 30px 0px;
    font-weight: 700;
    text-transform: capitalize;
}
.pagesheaderpage .otherpagestitle span{
    color:var(--primaryColor);
}

@media screen and (max-width:760px){
    .pagesheaderpage .otherpagestitle{
        font-size: 35px;
       
    }
}