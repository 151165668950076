.faq-container{
    padding:30px 0px 60px 0px;
}
.faq-page{
    padding: 30px 0px;
}
.faqs{
    width: 100%;
    margin:0 auto;
}
.faqs .faq{
    margin: 5px 0px 10px 0px;
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border:2px solid #e4e2e2!important;
}
.faqs .faq-question {
    position: relative;
    font-size: 17px;
    font-weight: 550;
    transition: all 0.4s ease;
    font-family: var(--textFamily);
    color: var(--blackColor);
    text-align: left;
    line-height: 30px;
}

.faqs  .faq-question::after{
    content:'';
    position: absolute;
    top: 50%;
    right: 0px;
    transform:translateY(-50%);
    width: 30px;
    height: 30px;  
    background-image: url("../arrows.PNG");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.4s ease;
}
.faqs  .faq-answer{
    opacity:0;
    max-height: 0;
    overflow-y: hidden;
    transition:  all 0.4s ease;
    color: rgb(36, 35, 35);
    font-size: 17px; 
    font-family: var(--textFamily);
    font-weight: 500;
    background:  rgb(228, 227, 227);
    text-align: justify;
    padding: 5px 10px;
    line-height: 30px;
    cursor: default;
     
}
.faqs  .faq-answer span a{
    color: var(--purpleColor);
    cursor: pointer;
    padding: 5px 10px;
    font-weight: 600;
    font-family: var(--textFamily);
    transition: 0.4s all ease-in-out;
}
.faqs  .faq-answer span a:hover{
    color: var(--primaryColor)
}
.faqs  .open .faq-question{
    margin-bottom: 25px;
}
.faqs  .open .faq-question::after{
    transform: translateY(-50%) rotate(90deg);
}
.faqs  .open .faq-answer{
    max-height: 1000px;
    opacity: 1;
}

@media screen and (max-width: 760px){
    .faqs .faq-question {
        position: relative;
        font-size: 16px;
        
    } 
    .faqs  .faq-answer{
        font-size: 16px; 
        font-family: var(--textFamily);
        font-weight: 500;
        background:  rgb(228, 227, 227);
        text-align: left;
        cursor: default;
        height: auto;    
    }
    .faqs  .faq-question::after{
        content:'';
        position: absolute;
        top: 52%;
        right: 0px;
        transform:translateY(-50%);
        width: 25px;
        height: 25px;  
        background-image: url("../arrows.PNG");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        transition: all 0.4s ease;
    }
}