*{
  margin:0;
  padding:0;
}

:root{
  --blackColor: #555454;
  --cursiveFamily:'Great Vibes', cursive;
  --indieFamily: 'Indie Flower', cursive;
  --headingFamily:'Quicksand', sans-serif;
  --textFamily: 'Raleway', sans-serif;
  --purpleColor:#be0bce;
  --primaryColor: #f3b10a;
  --backgroundColor: rgb(245, 245, 164);
}
body{
  background: #fff;
}
.bodyDark{
  background: linear-gradient(180deg, rgba(12, 12, 12, 0.445), rgba(8, 8, 8, 0.438));
}
.bg-button{
  margin: 30px 0px 30px 0px!important;
}
.bg-navlinkbtn{
  text-decoration: none;
  background: var(--purpleColor);
  color:#fff;
  padding: 15px 10px;
  border-radius: 4px;
  transition: 0.4s all ease;
  font-size: 20px;
  border: none;
}
.bg-navlinkbtn:hover{
  color:var(--purpleColor);
  background: #fff;
}
.bg-button-eBook .bg-navlinkbtn{
  background: #fff;
  color: var(--purpleColor);
  cursor: pointer;
}
.bg-button-eBook .bg-navlinkbtn:hover{
  background:var(--purpleColor);
  color:#fff
}
.loading{
  display: flex;
  justify-content: center;
  margin-top: 40px;
  padding: 30px 0px;
}
.loading::after{
  content: "";
  width: 50px;
  height: 50px;
  border: 10px solid #dddddd;
  border-top-color: var(--primaryColor);
  border-bottom-color: var(--purpleColor);
  border-radius: 50%;
  animation: loading 1s linear infinite!important;
}
@keyframes loading {
  to{
      transform:rotate(1turn);
  }
}

.no-show{
  display: none;
  transition: 0.4s all ease;
}
.popup-link{
  color:#fff;
}
.popup-link:hover{
  color: #fff
}
.popup{
  position: fixed;
  top: 300px;
  background: var(--purpleColor);
  font-family: var(--textFamily);
  font-size: 20px;
  padding: 10px 10px;
  border-radius: 5px;
  z-index: 20;
  display: block;
  cursor:pointer;
  box-shadow:#c4c4c4 1px 1px 5px 5px;
  -webkit-animation: fadein 5s infinite; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 5s infinite; /* Firefox < 16 */
        -ms-animation: fadein 5s infinite; /* Internet Explorer */
         -o-animation: fadein 5s infinite; /* Opera < 12.1 */
            animation: fadein 5s infinite;
}

@keyframes fadein {
  from { opacity: 0.4; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0.4; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0.4; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0.4; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0.4; }
  to   { opacity: 1; }
}

.popup:hover{
  background: var(--primaryColor);
  color: rgb(226, 221, 221)
}